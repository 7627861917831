define("discourse/plugins/discourse-directoryopus/discourse/linkopuslanding-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // To add routes to the root, return the map function itself rather than an object with a map function member.
  function _default() {
    this.route('linkopuslanding', {
      path: '/link-opus',
      resetNamespace: true
    });
  }
});