define("discourse/plugins/discourse-directoryopus/discourse/connectors/user-profile-primary/directoryopus-link-status-profile", ["exports", "discourse/plugins/discourse-directoryopus/lib/directoryopus-link-lib"], function (_exports, _directoryopusLinkLib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      _directoryopusLinkLib.default.setupUserProfileComponent(args.model, component, false, this.currentUser);
    }
  };
});